
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { ElTable } from "element-plus";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "standard-operating-procedure-information",
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      system_status: 10,
      subject: "",
      type: "",
      apply_to: [],
      check_list: [],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });
    const { t } = useI18n();

    const rules = ref({
      subject: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      apply_to: [
        {
          required: true,
          // asyncValidator: (rule, value) => {
          //   return new Promise<void>((resolve, reject) => {
          //     console.log(formData.value.type);
          //     if (formData.value.type != "1") {
          //       reject(t("common.isRequired"));
          //     } else {
          //       resolve();
          //     }
          //   });
          // },
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([
      // { label: "Account Validation", value: "1" },
      // { label: "Call-Out", value: "2" },
      // { label: "Quick Inquiry", value: "3" },
    ]);
    const typeLoading = ref<boolean>(false);
    const ouickInquiryApplyToOptions = ref([]);
    const callOutApplyToOptions = ref([]);
    const applyToLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          // "sop_apply_to",
          "quick_inquiry_comment_tagging",
          "call_out_subject",
          "distribution_task_type",
        ],
      });
      if (data.code == 0) {
        subjectOptions.value = data.data.call_out_subject.items;
        callOutApplyToOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        ouickInquiryApplyToOptions.value = data.data.call_out_subject.items;
        typeOptions.value = data.data.distribution_task_type.items;
      }
    };

    const typeChange = () => {
      formData.value.apply_to = [];
    };

    const handleCurrentChange = (e, row) => {
      updateCheckListTable({
        ...row,
        checked: e ? 1 : 0,
      });
    };

    const getStandardOperatingProcedureInfo = async (type = 0) => {
      const { data } =
        await ApiTaskManagement.getStandardOperatingProcedureInfo({
          id: route.params.id,
        });
      if (data.code == 0) {
        if (type === 1) {
          formData.value.check_list = data.data.check_list;
        } else {
          formData.value = data.data;
        }
      }
    };

    const handleItemInputChange = (e, row) => {
      handleUpdateProcedure({
        ...row,
        check_list: e,
      });
    };

    const handleAddProcedure = async () => {
      loading.value = true;
      const { data } =
        await ApiTaskManagement.addStandardOperatingProcedureCheckList({
          tasks_standard_operating_procedure_id: route.params.id,
          check_list: "",
          checked: 0,
        });
      loading.value = false;
      if (data.code == 0) {
        getStandardOperatingProcedureInfo(1);
      }
    };

    const updateCheckListTable = async (row) => {
      const { data } =
        await ApiTaskManagement.updateStandardOperatingProcedureCheckList(row);
      if (data.code == 0) {
        // console.log(data.data);
      } else {
        multipleTableRef.value?.toggleRowSelection(
          row,
          row.checked == 1 ? true : false
        );
      }
    };

    const updateCheckListItems = _.debounce(updateCheckListTable, 1000);

    const handleUpdateProcedure = (row) => {
      updateCheckListItems(row);
    };

    const handleDeleteProcedure = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Standard Operating Procedure Check List?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          ApiTaskManagement.deleteStandardOperatingProcedureCheckList({
            id: row.id,
          })
            .then(({ data }) => {
              loading.value = false;
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Standard Operating Procedure has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  getStandardOperatingProcedureInfo(1);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getStandardOperatingProcedureInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiTaskManagement.updateStandardOperatingProcedure(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteStandardOperatingProcedure = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Standard Operating Procedure?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiTaskManagement.deleteStandardOperatingProcedure({
            id: route.params.id,
          })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Standard Operating Procedure has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "standard-operating-procedure" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    onBeforeMount(() => {
      getFormInfo();
      getDropdownOptions();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      multipleTableRef,
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      applyToLoading,
      ouickInquiryApplyToOptions,
      callOutApplyToOptions,
      typeChange,
      submit,
      t,
      deleteStandardOperatingProcedure,
      handleCurrentChange,
      handleItemInputChange,
      handleAddProcedure,
      handleUpdateProcedure,
      handleDeleteProcedure,
    };
  },
});
